import {
    PUBLIC_ENVIRONMENT,
    PUBLIC_SENTRY_DSN,
    PUBLIC_BASE_URL,
    PUBLIC_LONG_TASKS_BASE_URL,
    PUBLIC_ICEBERG_LONG_TASKS_BASE_URL,
} from '$env/static/public';
export const appConfig = {
    baseUrl: PUBLIC_BASE_URL,
    longTasksBaseUrl: PUBLIC_LONG_TASKS_BASE_URL,
    icebergLongTasksBaseUrl: PUBLIC_ICEBERG_LONG_TASKS_BASE_URL,
    kixiePowerlists: {
        linkedin: 151377,
        linkedinGuessing: 151378,
    },
    sentryDsn: PUBLIC_SENTRY_DSN,
    smartLeadCampaigns: {
        // Regular
        electricianPlaintext: 213443,
        mentalHealthTherapistPlaintext: 213142,
        radtechPlaintext: 211824,
        physicalTherapistPlaintext: 469044,
        // Guessing
        electricianGuessingContactPlaintext: 229199,
        mentalHealthTherapistGuessingContactPlaintext: 229950,
        radtechGuessingContactPlaintext: 229930,
        // Regular & Guessing
        journeymanPipefitterRegularAndGuessingPlaintext: 618734,
        journeymanPlumberRegularAndGuessingPlaintext: 627998,
        journeymanCarpenterRegularAndGuessingPlaintext: 628041,
        pipefitterRegularAndGuessingPlaintext: 628050,
        plumberRegularAndGuessingPlaintext: 631458,
        hvacTechnicianRegularAndGuessingPlaintext: 639058,
        welderRegularAndGuessingPlaintext: 639062,
        heavyEquipmentOperatorRegularAndGuessingPlaintext: 639083,
        carpenterRegularAndGuessingPlaintext: 639522,
        sheetMetalWorkerRegularAndGuessingPlaintext: 639539,
        cncMachinistRegularAndGuessingPlaintext: 639557,
        electricalForemanRegularAndGuessingPlaintext: 1317500,
        electricalSuperintendentRegularAndGuessingPlaintext: 1317811,
        refrigerationTechnicianRegularAndGuessingPlaintext: 1351257,
        concreteForemanRegularAndGuessingPlaintext: 1351353,
        concreteSuperintendentRegularAndGuessingPlaintext: 1351383,
        concreteFinisherRegularAndGuessingPlaintext: 1351314,
        plumbingForemanRegularAndGuessingPlaintext: 1351413,
        plumbingSuperintendentRegularAndGuessingPlaintext: 1351459,
        pipingForemanRegularAndGuessingPlaintext: 1351512,
        pipingSuperintendentRegularAndGuessingPlaintext: 1351528,
        hvacForemanRegularAndGuessingPlaintext: 1351546,
        hvacSuperintendentRegularAndGuessingPlaintext: 1351557,
        millwrightForemanRegularAndGuessingPlaintext: 1351572,
        carpenterForemanRegularAndGuessingPlaintext: 1351598,
        millwrightRegularAndGuessingPlaintext: 1351613,
        cdlDriverRegularAndGuessingPlaintext: 1354145,
        serviceTechnicianRegularAndGuessingPlaintext: 1454442,
    },
    environment: PUBLIC_ENVIRONMENT || 'development',
};
